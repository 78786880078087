export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();
  const trolleyStore = useTrolleyStore();
  
  const localePath = useLocalePath();
  
  if (!authStore.is_authenticated && !authStore.is_guest_checkout) {
    const rootStore = useRootStore();
    rootStore.after_auth_intended_route = to.fullPath;
    return await navigateTo(localePath("/auth/signin?type=guest"));
  }
  if (!trolleyStore.trolley_id) {
    return await navigateTo(localePath("/trolley"));
  }
});
